
import { Component, Watch, Mixins } from "vue-property-decorator";
import { appsWithJntAccount } from "../api/queries/apps-with-jnt-account";
import { jntAccountUpdate } from "@/api/mutations/jnt-account";
import _ from "lodash"; // Import lodash
import LoaderMixin from "@/mixins/LoaderMixin";
import PaginatorMixin from "@/mixins/PaginatorMixin";
import BreakpointMixin from "@/mixins/BreakpointMixin";
import moment from "moment-mini";
import { SHORT_DATE_FORMAT } from "../utils/constants";

@Component({
  apollo: {
    appsWithJntAccount: {
      query: appsWithJntAccount,
      // hide-default-footer (temp hide paginator, items-per-page="-1" shows all rows)
      // variables() {
      //   return {
      //     first: 10, // Adjust based on pagination settings
      //   };
      // },
      variables() {
        return {
          keywords: this.keywordsSearch,
          statusFilter: this.searchStatusFilter,
          dateFilterFrom: this.rangeFrom,
          dateFilterTo: this.rangeTo,
        };
      },
      update({ appsWithJntAccount }: any) {
        return appsWithJntAccount.edges.map(({ node }: any) => {
          const { jntAccount, appName, uuid } = node;
          const existingValidationStatus = jntAccount?.validationStatus;
          const validationStatus =
            existingValidationStatus === "for_validation"
              ? "forValidation"
              : existingValidationStatus;

          return {
            ...node,
            appName,
            uuid,
            customerId: jntAccount?.customerId,
            dateSubmitted: this.formatDate(jntAccount?.dateSubmitted),
            validationStatus,
          };
        });
      },
    },
}
})
export default class JntAccounts extends Mixins(
  LoaderMixin,
  PaginatorMixin,
  BreakpointMixin
) {
  private appsWithJntAccount = [];

  private keywords = "";

  private keywordsSearch = "";

  private statusFilter = "all";

  private searchStatusFilter = "";

  private previousStatus = "";

  private rangeFrom = moment().startOf("year").format(SHORT_DATE_FORMAT);

  private rangeTo = moment().format(SHORT_DATE_FORMAT);

  private recreateDebouncedFunctions() {
    this.debouncedUpdateSearchStatusFilter.cancel();
    this.debouncedUpdateKeywordsSearch.cancel();
    this.debouncedUpdateSearchStatusFilter();
    this.debouncedUpdateKeywordsSearch();
  }

  @Watch("appUuids")
  @Watch("statusFilter")
  @Watch("keywords")
  @Watch("rangeFrom")
  @Watch("rangeTo")
  private onFilterChange() {
    this.recreateDebouncedFunctions();
  }

  private updateSearchStatusFilter() {
    this.searchStatusFilter = this.statusFilter;
  }

  private updateSearchKeywords() {
    this.keywordsSearch = this.keywords;
  }

  private debouncedUpdateSearchStatusFilter = _.debounce(
    this.updateSearchStatusFilter,
    1500
  );

  private debouncedUpdateKeywordsSearch = _.debounce(
    this.updateSearchKeywords,
    1500
  );

  private debouncedUpdateDateFromFilter = _.debounce(
    this.updateDateFromFilter,
    1500
  );

  private debouncedUpdateDateToFilter = _.debounce(
    this.updateDateToFilter,
    1500
  );

  private updateDateFromFilter() {
    this.rangeFrom;
  }

  private updateDateToFilter() {
    this.rangeTo;
  }

  private get headers() {
    const commonProps = {
      align: "center",
      sortable: false,
      class: "font-weight-bold text-uppercase grey lighten-4 py-4",
    };

    return [
      {
        ...commonProps,
        text: "APP NAME",
        align: "start",
        value: "appName",
      },
      {
        ...commonProps,
        text: "J&T CUSTOMER ID",
        align: "start",
        value: "customerId",
      },
      {
        ...commonProps,
        text: "DATE SUBMITTED",
        align: "start",
        value: "dateSubmitted",
      },
      {
        ...commonProps,
        text: "ACCOUNT VALIDATION STATUS",
        align: "start",
        value: "validationStatus",
        width: "20%",
      },
    ];
  }

  private formatDate(dateString: string): string {
    if (!dateString) return "";

    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", options);
  }

  private validationStatusOptions = [
    { text: "All", value: "all" },
    { text: "For Validation", value: "forValidation" },
    { text: "Successful", value: "successful" },
    { text: "Failed", value: "failed" },
    { text: "Disconnected", value: "disconnected" },
  ];

  private get filteredValidationStatusOptions() {
    return this.validationStatusOptions.filter(
      (option) => option.value !== "all"
    );
  }

  private async updateStatus(appUuid: string, validationStatus: string) {
    if (validationStatus === "forValidation") {
      validationStatus = "for_validation";
    }
    try {
      await this.$apollo.mutate({
        mutation: jntAccountUpdate,
        variables: {
          input: {
            appUuid,
            validationStatus,
          },
        },
      });
    } catch (error: any) {
      console.error("Error updating validation status:", error.message);

      if (error.graphQLErrors) {
        error.graphQLErrors.forEach((err: any, index: number) => {
          console.error(`GraphQL Error ${index + 1}: ${err.message}`);
          console.error(`Path: ${err.path}`);
          console.error(`Extensions:`, err.extensions);
        });
      }

      if (error.networkError) {
        console.error("Network Error:", error.networkError.message);
      }
    }
  }

  private displayValidationStatus(item): string {
    if (
      item.validationStatus === "for_validation" ||
      item.validationStatus === "forValidation"
    ) {
      return "For Validation";
    } else if (item.validationStatus === "successful") {
      return "Successful";
    } else if (item.validationStatus === "failed") {
      return "Failed";
    } else if (item.validationStatus === "disconnected") {
      return "Disconnected";
    }
    return item.validationStatus;
  }

  private isSuccessful(status: string) {
    return status.toLowerCase() === "successful";
  }

  private isFailed(status: string) {
    return status.toLowerCase() === "failed";
  }

  private isDisconnected(status: string) {
    return status.toLowerCase() === "disconnected";
  }

  private isForValidation(status: string) {
    return status === "forValidation";
  }
}
